import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class ImageCarousel extends Component {
  state = {
    itemNumber: 0,
  }
  componentDidMount() {
    this.interval = setInterval(this.nextItemNumber, 8000)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  nextItemNumber = () => {
    const { itemNumber } = this.state
    const { items } = this.props
    const nextNumber = itemNumber + 1 === items.length ? 0 : itemNumber + 1
    this.setState({ itemNumber: nextNumber })
  }
  prevItemNumber = () => {
    const { itemNumber } = this.state
    const { items } = this.props
    const nextNumber = itemNumber - 1 < 0 ? items.length - 1 : itemNumber - 1
    this.setState({ itemNumber: nextNumber })
  }
  render() {
    const { containerClassName, items = [] } = this.props
    const { itemNumber } = this.state

    if (items.length === 0) {
      return null
    }

    const currentItem = items[itemNumber]
    const images = items.map(item => item.image)
    const style = { backgroundImage: `url(${currentItem.image})`, transition: 'background-image 1s' }

    const addLeadingZero = number => (Number.isInteger(number) && number < 10 ? `0${number}` : number)

    return (
      <div className={`carousel ${containerClassName || ''}`} style={style}>
        {this.props.children}

        {currentItem && (
          <div className="content">
            <div className="arrow" onClick={this.prevItemNumber}>
              <i className="icon-left" />
            </div>
            <div className="text-container item-count">
              <div className="current">{addLeadingZero(itemNumber + 1)}</div>
              <div className="total">/{addLeadingZero(items.length)}</div>
            </div>
            <div className="text-container description">
              <h1>{currentItem.title}</h1>
              <h4>{currentItem.subtitle}</h4>
              <a href={currentItem.link} target="_blank" rel="noopener noreferrer">
                + Ver más
              </a>
            </div>
            <div className="arrow" onClick={this.nextItemNumber}>
              <i className="icon-right" />
            </div>
          </div>
        )}

        {// Force image preload
        images.map((imgSrc, idx) => (
          <img key={idx} src={imgSrc} width="0" height="0" alt={`preload ${idx}`} />
        ))}
      </div>
    )
  }
}
