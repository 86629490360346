import React, { Component } from 'react'
import { connect } from 'redux-zero/react'
import * as actions from '../store'
import { Page } from './Page'
import { Column } from './Column'
import { Loading } from './Loading'
import ReactMarkdown from 'react-markdown'
import { Footer } from './Footer'
import { LoadingError } from './LoadingError'

export class TeamMember extends Component {
  state = {
    pageId: '1',
  }

  componentDidMount() {
    actions.loadTeamMembers()
  }

  render() {
    const { teamMembers, match } = this.props
    const { slug } = match && this.props.match.params
    if (!teamMembers || !slug) {
      return <Loading />
    }

    const member = teamMembers.find(({ slug: memberSlug }) => memberSlug === slug)

    if (!member) {
      return <LoadingError />
    }

    const { pageId } = this.state
    const currentPage = member.pages.find(({ id }) => id === pageId)
    const pagesCount = member.pages.length

    return (
      <Page>
        <Column>
          <div className="single-member">
            <div className="member-details">
              <h2>{member.name}</h2>
              <div className="member-details-box">
                <div className="name">{member.title}</div>
                <div className="email">{member.email}</div>
                <div className="email">{member.phone}</div>
                {member.linkedInProfileUrl && (
                  <a href={member.linkedInProfileUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src="/images/social/linkedin-logo.svg"
                      alt="torre.co profile"
                      height="44"
                      style={{ padding: '10px' }}
                    />
                  </a>
                )}
                {member.torreCoProfileUrl && (
                  <a href={member.torreCoProfileUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src="/images/social/torre-co-logo.png"
                      alt="torre.co profile"
                      height="44"
                      style={{ padding: '10px' }}
                    />
                  </a>
                )}
              </div>
            </div>

            <div className="photo" style={{ backgroundImage: `url(${member.image})` }} />

            <div className="team-member-pages">
              {pagesCount > 1
                ? member.pages.map(page => (
                    <div
                      className={`page-selector ${page.id === pageId ? 'active' : ''}`}
                      key={page.id}
                      onClick={() => this.setState({ pageId: page.id })}
                    />
                  ))
                : null}
              <div className="page-title">{currentPage && currentPage.title}</div>
              <div className="page-content">{currentPage && <ReactMarkdown source={currentPage.content} />}</div>
            </div>
          </div>
        </Column>
        <Footer withLinkToContact />
      </Page>
    )
  }
}

TeamMember = connect(state => state)(TeamMember)
