import React, { Component } from 'react'
import { connect } from 'redux-zero/react'
import { Link } from 'react-router-dom'
import * as actions from '../store'
import { Page } from './Page'
import { Column } from './Column'
import { Loading } from './Loading'
import { Footer } from './Footer'

const Members = ({ children }) => <div className="team-members-list">{children}</div>

const Member = ({ member }) => {
  return (
    <div className="member">
      <div className="photo" style={{ backgroundImage: `url(${member.image})` }} />
      <div className="name">{member.name}</div>
      <div className="name">{member.title}</div>
      <div className="email">{member.email}</div>
      <Link to={`/equipo/${member.slug}`}>Leer biografía</Link>
    </div>
  )
}

export class Team extends Component {
  componentDidMount() {
    actions.loadTeamMembers()
  }

  render() {
    const { teamMembers } = this.props
    const { loadingPage } = this.props

    if (loadingPage) {
      return <Loading />
    }

    return (
      <Page>
        <Column>
          <div className="team-members">
            <h1>Nuestro equipo de trabajo</h1>
            <Members>{teamMembers && teamMembers.map(member => <Member key={member.id} member={member} />)}</Members>
          </div>
        </Column>
        <Footer withLinkToContact />
      </Page>
    )
  }
}

Team = connect(state => state)(Team)
