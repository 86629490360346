import React, { useState, useEffect } from 'react'
import { YouTubeVideo } from './YouTubeVideo'
import ReactMarkdown from 'react-markdown'

const ClientsCarouselItem = ({ item }) => {
  const { image, text, client, youtubeVideoId } = item
  return (
    <div className="clients-carousel-item">
      <div className="client-quote">
        {youtubeVideoId ? (
          <YouTubeVideo videoId={youtubeVideoId} />
        ) : (
          <>
            {image && <div className="client-quote-img" style={{ backgroundImage: `url(${image})` }} />}
            <div className="client-quote-text">
              <div className="text">
                <blockquote>{text}</blockquote>
              </div>
              <ReactMarkdown className="client" source={client} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const ClientsCarousel = ({ items = [] }) => {
  const [itemNumber, setItemNumber] = useState(0)
  const nextItemNumber = () => setItemNumber(itemNumber + 1 === items.length ? 0 : itemNumber + 1)
  const prevItemNumber = () => setItemNumber(itemNumber - 1 < 0 ? items.length - 1 : itemNumber - 1)

  if (items.length === 0) {
    return null
  }

  const currentItem = items[itemNumber]

  return (
    <div className="clients-carousel">
      {currentItem && (
        <div className="content">
          <div className="arrow" onClick={prevItemNumber}>
            <i className="icon-left" />
          </div>

          <ClientsCarouselItem item={currentItem} />

          <div className="arrow" onClick={nextItemNumber}>
            <i className="icon-right" />
          </div>
        </div>
      )}
    </div>
  )
}
