import React from 'react'
import { Link } from 'react-router-dom'

const withLink = Component => props => {
  const { link, number } = props

  if (link) {
    const isLocalLink = link.startsWith('/')
    return isLocalLink ? (
      <Link className={`service-title-box local ${number ? '' : 'no-number'}`} to={link}>
        <Component {...props} />
      </Link>
    ) : (
      <a
        className={`service-title-box ${number ? '' : 'no-number'}`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Component {...props} />
      </a>
    )
  }
  return (
    <div className={`service-title-box ${number ? '' : 'no-number'}`}>
      <Component {...props} />
    </div>
  )
}

export const ServiceTitleBox = withLink(({ number = '', content, iconClassName, link }) => (
  <>
    {number && <div className="number">{number}</div>}
    <div className="title">{content}</div>
    <div className="corner">
      <i className={iconClassName} />
    </div>
  </>
))
