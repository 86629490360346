import React, { Component } from 'react'
import * as actions from '../store'
import ReactMarkdown from 'react-markdown'
import { Loading, LoadingError, Page, Column, ColumnContent, Footer, YouTubeVideo, ServiceTitleBox } from './'
import { connect } from 'redux-zero/react'

export class ContentPage extends Component {
  componentWillMount() {
    const { section, pageSlug } = this.props.match.params
    actions.loadPage(section, pageSlug)
  }

  componentWillReceiveProps(nextProps) {
    const { section, pageSlug } = this.props.match.params
    const { section: nextSection, pageSlug: nextPageSlug } = nextProps.match.params
    if (section !== nextSection || pageSlug !== nextPageSlug) {
      actions.loadPage(nextSection, nextPageSlug)
    }
  }

  render() {
    const { section, pageSlug } = this.props.match.params
    const pageKey = pageSlug ? `page-${section}-${pageSlug}` : `page-${section}`
    const page = this.props[pageKey]
    const { loadingPage } = this.props
    if (loadingPage) {
      return <Loading />
    }
    if (!page || !page.id) {
      return <LoadingError />
    }
    const { layout, title, number, subtitle, image, content, youtubeVideoId } = page
    if (layout === 1) {
      return (
        <Page>
          <Column color="alpha-blue">
            <ColumnContent layout={layout}>
              <ReactMarkdown source={title} />
              <ReactMarkdown source={content} />
            </ColumnContent>
          </Column>
          <Column size="big">
            <ColumnContent layout={layout}>
              <div className="layout-one-image" style={{ backgroundImage: `url(${image})` }} />
            </ColumnContent>
          </Column>
          <Footer withLinkToContact />
        </Page>
      )
    } else if (layout === 2) {
      return (
        <Page bgImage={image}>
          <Column color="alpha-blue medium">
            <ColumnContent layout={layout}>
              <ServiceTitleBox number={number} content={title} iconClassName="icon-right" />
            </ColumnContent>
          </Column>
          <Column color="alpha-blue">
            <ColumnContent layout={layout}>
              <ReactMarkdown source={content} />
              {youtubeVideoId && <YouTubeVideo videoId={youtubeVideoId} />}
            </ColumnContent>
          </Column>
          <Footer withLinkToContact />
        </Page>
      )
    } else if (layout === 3) {
      return (
        <Page bgImage={image}>
          <Column color="alpha-blue">
            <ColumnContent layout={layout}>
              <ReactMarkdown source={title} />
              <div className="subtitle">
                <ReactMarkdown source={subtitle} />
              </div>
              <ReactMarkdown source={content} />
            </ColumnContent>
          </Column>
          <Column color="red" size="medium">
            <ColumnContent layout={layout}>
              <div className="layoutTwoImage" style={{ backgroundImage: `url(${image})` }} />
            </ColumnContent>
          </Column>
          <Footer withLinkToContact />
        </Page>
      )
    } else if (layout === 4) {
      return (
        <Page bgImage={image}>
          <Column color="alpha-blue">
            <ColumnContent layout={layout}>
              <ReactMarkdown source={title} />
              <ReactMarkdown source={content} />
            </ColumnContent>
          </Column>
          <Column color="alpha-blue">
            <ColumnContent layout={layout}>{youtubeVideoId && <YouTubeVideo videoId={youtubeVideoId} />}</ColumnContent>
          </Column>
          <Footer withLinkToContact />
        </Page>
      )
    }
    return null
  }
}

ContentPage = connect(state => state)(ContentPage)
