import React, { Component } from 'react'
import * as actions from '../store'
import { connect } from 'redux-zero/react'
import { Footer, ImageCarousel, Page, ServiceTitleBox } from './'
// import { ClientLogo } from './ClientLogo'
// import { Link } from 'react-router-dom'

export class IndexPage extends Component {
  componentWillMount() {
    actions.loadCarouselItems()
    actions.loadClientsPageData()
  }

  render() {
    const { carouselItems, clientLogos } = this.props
    return (
      <>
        <ImageCarousel items={carouselItems} containerClassName="home-page" />

        {/* <Page bgImage="/images/bg-index-us.jpg">
          <div className="index-text-section">
            <h1>Nosotros</h1>
            <p>
              Somos un estudio especializado en asuntos educativos, en la protección de la infancia y la adolescencia y
              que habla su mismo idioma.
            </p>
            <Link className="btn" to="/nosotros">
              Conozca Edulegal
            </Link>
          </div>
        </Page>

        <Page bgImage="/images/bg-index-team.jpg">
          <div className="index-text-section">
            <h1>El Equipo</h1>
            <p>Somos un equipo experto en Educación y Derechos humanos.</p>
            <Link className="btn" to="/equipo">
              Conozca al equipo
            </Link>
          </div>
        </Page>

        <Page bgImage="/images/bg-index-services.jpg">
          <div className="index-text-section large services-list">
            <h1>Servicios</h1>
            <ServiceTitleBox
              number="1"
              content="Educación Preescolar, Básica y Media"
              iconClassName="icon-plus"
              link="/servicios/preescolar-basica-media"
            />
            <ServiceTitleBox
              number="2"
              content="Entidades Estatales"
              iconClassName="icon-plus"
              link="/servicios/entidades-estatales"
            />
            <ServiceTitleBox
              number="3"
              content="Instituciones de Educación para el Trabajo y el Desarrollo Humano"
              iconClassName="icon-plus"
              link="/servicios/instituciones-educacion-trabajo-desarrollo"
            />
            <ServiceTitleBox
              number="4"
              content="Entidades que protegen derechos de la infancia y la adolescencia Humano"
              iconClassName="icon-plus"
              link="/servicios/derechos-infancia-adolescencia"
            />
            <ServiceTitleBox
              number="5"
              content="Trámites de interés general"
              iconClassName="icon-plus"
              link="/servicios/tramites"
            />
            <ServiceTitleBox number="6" content="Pro Bono" iconClassName="icon-plus" link="/servicios/pro-bono" />
          </div>
        </Page>

        <Page bgImage="/images/bg-index-clients.jpg">
          <div className="index-text-section large clients-list">
            <h1>Conozca algunos de nuestros Clientes</h1>
            <div className="client-logos">
              {clientLogos.map(logo => (
                <ClientLogo key={logo.id} logo={logo} />
              ))}
            </div>
          </div>
        </Page> */}

        <Footer />
      </>
    )
  }
}

IndexPage = connect(state => state)(IndexPage)
