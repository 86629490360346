import React from 'react'
import { NavLink } from 'react-router-dom'

export const HeaderLink = ({ exact = false, to = '#', children, subItems = [], onClick }) => (
  <li>
    <NavLink
      exact={exact}
      to={to}
      activeClassName={to === '#' ? '' : 'active'}
      onClick={event => {
        // Disable link if the item have subitems.
        if (to === '#') {
          event.preventDefault()
        } else {
          onClick(event)
        }
      }}
    >
      {children}
    </NavLink>
    {subItems.length ? <div className="down-arrow"></div> : null}
    {subItems.length ? (
      <ul>
        {subItems.map(item => (
          <HeaderLink key={item.id} to={item.to} activeClassName="active" onClick={onClick}>
            {item.text}
          </HeaderLink>
        ))}
      </ul>
    ) : null}
  </li>
)
