import React from 'react'
import { connect } from 'redux-zero/react'
import * as actions from '../store'
import { Map } from './Map'

export const Contact = connect(state => state)(({ contactForm, contactFormSent }) => (
  <div className="contact-page">
    <div className="info">
      <h1>Contáctenos</h1>
      <div>Dirección: Carrera 11 A No. 93A - 80</div>
      <div>Oficina 304</div>
      <div>Teléfono: + 57 (1) 623-6451</div>
      <div>info@educandojovenes21.org</div>
    </div>
    <div className="form">
      <form onSubmit={actions.sendContactForm}>
        <input name="name" type="text" placeholder="Nombre" onChange={actions.onContactFormFieldChange} />
        <input name="email" type="email" placeholder="E-mail" onChange={actions.onContactFormFieldChange} />
        <textarea name="mensaje" type="text" placeholder="Mensaje" onChange={actions.onContactFormFieldChange} />
        <div className="form-bottom">
          <div>
            <label htmlFor="robot" className="container" onClick={actions.onContactFormRobotFieldChange}>
              <input name="robot" type="checkbox" checked={contactForm.robot} />
              <span className="checkmark" />
              No soy un robot
            </label>
          </div>
          <input type="submit" value="ENVIAR" disabled={contactFormSent} />
        </div>
        <span className={`thanksMessage ${contactFormSent ? 'visible' : ''}`}>
          Gracias por comunicarse con nosotros
        </span>
      </form>
    </div>
    <div className="map-container">
      <Map />
    </div>
  </div>
))
