const menuItems = [
  { id: '1', to: '/', text: 'Inicio', exact: true },
  { id: '2', to: '/nosotros', text: 'Nosotros' },
  {
    id: '3',
    to: '/equipo',
    text: 'Equipo',
    subItems: [
      { id: '1', to: '/equipo/juan-manuel', text: 'Juan Manuel Uribe González' },
      { id: '2', to: '/equipo/andres', text: 'Andrés Vélez Serna' },
    ],
  },
  {
    id: '4',
    to: '#',
    text: 'Proyectos',
    subItems: [
      { id: '1', to: '/proyectos/colegio-los-nogales', text: 'Colegio Los Nogales' },
      { id: '2', to: '/proyectos/hogares-bambi', text: 'Fundación Ayuda a la Infancia Hogares Bambi' },
      { id: '3', to: '/proyectos/universidad-de-los-andes', text: 'Universidad de los Andes' },
      { id: '4', to: '/proyectos/ensena-por-colombia', text: 'Enseña por Colombia' },
      { id: '5', to: '/proyectos/fundacion-mezuena', text: ' Fundación Mezuena' },
    ],
  },
  {
    id: '5',
    to: '#',
    text: 'Información',
    subItems: [{ id: '1', to: '/informacion/informacion-tributaria', text: 'Información Régimen Tributario Especial' }],
  },
  { id: '6', to: '/contacto', text: 'Contacto' },
]

const carouselItems = [
  {
    id: '1',
    image: '/images/carousel/1.jpg',
    title: 'Estudiantes de Cajicá ingresan al Colegio Los Nogales',
    subtitle: '',
    link: '/proyectos/colegio-los-nogales',
  },
  {
    id: '2',
    image: '/images/carousel/2.jpg',
    title: 'Apoyo al desarrollo integral de la primera infancia y de la maternidad.',
    subtitle: '',
    link: '/proyectos/hogares-bambi',
  },
  {
    id: '3',
    image: '/images/carousel/3.jpg',
    title: 'Contribución al desarrollo del Programa Quiero Estudiar de la Universidad de los Andes.',
    subtitle: '',
    link: '/proyectos/universidad-de-los-andes',
  },
  {
    id: '4',
    image: '/images/carousel/4.jpg',
    title: 'Apoyo al programa de enseñanza de inglés de la Fundación Mezuena.',
    subtitle: '',
    link: '/proyectos/fundacion-mezuena',
  },
]

const pages = [
  // {
  //   id: '1',
  //   section: '',
  //   slug: '',
  //   title: '',
  //   subtitle: '', // Secondary color title. (only layout 3)
  //   content: '', // Markdown
  //   youtubeVideoId: '', // If youtubeVideoId is present, render content under the title.
  //   image: '',
  //   layout: , // 1: content/image, 2: title in block, 3: red right column
  // },
  {
    id: '6',
    number: '1', //TODO: Add to contentful
    section: 'proyectos',
    slug: 'colegio-los-nogales',
    title: 'Colegio Los Nogales',
    content: `Jóvenes del Siglo 21 cofinancia un proyecto para asegurar que estudiantes de Cajicá puedan ingresar y permanecer en el Colegio Los Nogales, mediante un sistema de adaptación, apoyo y seguimiento orientado a asegurar el máximo bienestar de los estudiantes.`,
    layout: 2,
  },
  {
    id: '7',
    number: '2',
    section: 'proyectos',
    slug: 'hogares-bambi',
    title: 'Fundación Ayuda a la Infancia Hogares Bambi',
    content: `Jóvenes del Siglo 21 apoya el desarrollo de un programa de atención integral a la primera infancia y de apoyo a las madres de los niños que permanecen bajo el cuidado de esta entidad.`,
    layout: 2,
  },
  {
    id: '9',
    number: '3',
    section: 'proyectos',
    slug: 'universidad-de-los-andes',
    title: 'Universidad de los Andes',
    content: `Apoyamos el programa **Quiero Aprender** que desarrolla la Universidad de los Andes para asegurar que estudiantes que han sido aceptados pero que no cuentan con los recursos para pagar su matrícula, puedan estudiar en esta prestigiosa Universidad.`,
    layout: 2,
  },
  {
    id: '10',
    number: '4',
    section: 'proyectos',
    slug: 'ensena-por-colombia',
    title: 'Enseña por Colombia',
    content: `Apoyamos a Enseña por Colombia en su esfuerzo por lograr que profesionales recién egresados se vinculen por dos años en establecimientos educativos para fortalecer el proceso de educativo de estudiantes en lugares apartados o vulnerables.`,
    layout: 2,
  },
  {
    id: '11',
    number: '5',
    section: 'proyectos',
    slug: 'fundacion-mezuena',
    title: 'Fundación Mezuena',
    content: `Apoyamos el desarrollo de un programa de enseñanza de inglés a jóvenes ciclistas vinculados a la Fundación Mezuena. El programa busca crear nuevas oportunidades para estos jóvenes ciclistas de alto rendimiento.
    
![Tux, the Linux mascot](/images/mezuena-1.jpg)
    `,
    layout: 2,
  },
  {
    id: '12',
    number: '',
    section: 'informacion',
    slug: 'informacion-tributaria',
    title: 'Información Régimen Tributario Especial',
    content: `
Acta completa

[Ver](/documents/tributaria-2020/acta-7-asamblea.pdf)

Acta - Estados financieros

[Ver](/documents/tributaria-2020/estados.pdf)

Acta - Informe de getión

[Ver](/documents/tributaria-2020/informe-de-gestion.pdf)

Certificación Jovenes siglo 21

[Ver](/documents/tributaria-2020/certificacion-jovenes-siglo-21.pdf)

Certificación Representante Legal

[Ver](/documents/tributaria-2020/certificacion-rl.pdf)

Certificación Representante Legal - Antecedentes judiciales

[Ver](/documents/tributaria-2020/certificación-rl-antecedentes-judiciales.pdf)
    `,
    layout: 2,
  },
]

const clientQuotes = [
  // {
  //   image: '',
  //   text: '',
  //   client: ``,
  // },
  // {
  //   youtubeVideoId: 'tJExBsfOTiw',
  // },
]

const clientLogoImages = [
  {
    id: '1',
    image: '/images/clients/1.png',
    imageAlt: 'Colegio Los Nogales',
  },
  {
    id: '2',
    image: '/images/clients/2.png',
    imageAlt: 'Universidad de los Andes',
  },
  {
    id: '3',
    image: '/images/clients/3.png',
    imageAlt: 'Fundación Ayuda a la Infancia Hogares Bambi',
  },
  {
    id: '4',
    image: '/images/clients/4.png',
    imageAlt: 'Enseña por Colombia',
  },
  {
    id: '5',
    image: '/images/clients/5.png',
    imageAlt: 'Edulegal',
  },
]

const teamMembers = [
  {
    id: '1',
    name: 'Juan Manuel Uribe González',
    image: '/images/photo-team-1.jpg',
    title: 'Director Ejecutivo',
    email: 'juan@educandojovenes21.org',
    phone: '',
    linkedInProfileUrl: '',
    torreCoProfileUrl: 'https://bio.torre.co/en/juanuribeg',
    slug: 'juan-manuel',
    pages: [
      {
        id: '1',
        title: 'Experiencia',
        content: `Ingeniero Industrial de la Universidad de los Andes.

Tiene más de diecisiete años de experiencia profesional.

Se desempeñó en importantes cargos en el sector financiero, que abarcan banca corporativa, banca de inversión, manejo de capitales y private equity.

Así mismo, fue co-fundador de Appian Education LLC., una firma especializada en la inversión y gestión de establecimientos educativos.

Posteriormente se desempeñó como CFO para Latinoamérica de Educas International - Inspired Schools.

Ha sido miembro de órganos de gobierno de diferentes instituciones educativas. Es miembro del Comité Financiero de la Fundación Zoraida Cadavid de Sierra.

Fundó la Jóvenes del Siglo 21 con el propósito de apoyar el desarrollo de proyectos de alto impacto en el sector educativo que van desde la educación inicial hasta la educación superior.`,
      },
    ],
  },
  {
    id: '2',
    name: 'Andrés Vélez Serna',
    image: '/images/photo-team-2.jpg',
    title: 'Subdirector Ejecutivo',
    email: 'andres@educandojovenes21.org',
    phone: '+57 (1) 623-6451',
    linkedInProfileUrl: 'https://www.linkedin.com/in/andr%C3%A9s-v%C3%A9lez-serna-0a46a219/',
    torreCoProfileUrl: 'https://bio.torre.co/en/andres48',
    slug: 'andres',
    pages: [
      {
        id: '1',
        title: 'Experiencia',
        content: `Es abogado de la Pontificia Universidad Javeriana y cuenta con una Maestría en Derecho de Columbia University.

Es socio de Edulegal, firma de servicios legales especializada en el sector educativo y la protección de la infancia y a la adolescencia.

Se ha desempeñado como asesor para asuntos regulatorios del Ministerio de Educación Nacional y como asociado en Brigard & Urrutia Abogados S.A.S.`,
      },
    ],
  },
]

const blogItems = [
  {
    id: '1',
    type: 'noticia',
    title: `Departamento Administrativo de la Función Pública. Decreto 1016 de 6 de junio de 2019, fija la escala de remuneración de los docentes y directivos docentes que se rigen por el Decreto Ley 1278 de 2002.`,
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '2',
    type: 'noticia',
    title: `Departamento Administrativo de la Función Pública. Decreto 1017 de 6 de junio de 2019, fija la escala de remuneración de los docentes y directivos docentes que se rigen por el Decreto 2277 de 1979. `,
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '3',
    type: 'noticia',
    title: `Departamento Administrativo de la Función Pública. Decreto 1018 de 6 de junio de 2019, fija la escala de remuneración de los docentes y directivos docentes etnoeducadores`,
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '4',
    type: 'noticia',
    title:
      'Departamento Administrativo de la Función Pública. Decreto 1022 de 6 de junio de 2019, establece una bonificación para docentes y directivos docentes de preescolar, básica y media.',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '5',
    type: 'noticia',
    title:
      '5.	Ministerio de Educación Nacional Concepto 71251 de 31 de mayo de 2019. Sobre el cobro de copias de documentos académicos en instituciones educativas oficiales.',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '6',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 70624 de 30 de mayo de 2019. Sobre la naturaleza jurídica de las academias de lenguas. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '7',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 69092 de 28 de mayo de 2019 sobre el marco normativo de la educación inicial a la primera infancia. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '8',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-228 de 28 de mayo de 2019 que ampara el derecho a la educación a comunidades indígenas. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-228-19.htm',
  },
  {
    id: '9',
    type: 'noticia',
    title:
      'Congreso de la República Ley 1955 de 2019 “Por el cual se expide el Plan Nacional de Desarrollo 2018 -2022, Pacto por Colombia, Pacto por la Equidad” ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '10',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 63638 de 20 de mayo de 2019 sobre la expedición de títulos a estudiantes venezolanos cuya situación migratoria no se encuentra resuelta. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '11',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-209 de 17 de mayo de 2019 que ampara el derecho a la educación y ordena la realización de obras públicas para asegurar la accesibilidad. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-209-19.htm',
  },
  {
    id: '12',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-205 de 16 de mayo de 2019 que ampara el derecho a la educación inclusiva. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/T-205-19.htm',
  },
  {
    id: '13',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 62168 de 16 de mayo de 2019 sobre el creación de centros de idiomas en instituciones de educación superior. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '14',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-198 de 14 de mayo de 2019 que ampara el derecho a la educación y ordena que se ajuste el costo de la matrícula en la UPTC. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-198-19.htm',
  },
  {
    id: '15',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 57444 de 8 de mayo de 2019 sobre competencias de inspección y vigilancia. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '16',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 56884 de 8 de mayo de 2019 sobre exigencia de certificación médica. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '17',
    type: 'noticia',
    title:
      'Ministerio de Educación Nacional Concepto 56533 de 7 de mayo de 2019 sobre facultad sancionadora por las entidades que ejercen inspección y vigilancia. ',
    link: 'https://www.mineducacion.gov.co/1759/w3-propertyvalue-51455.html',
  },
  {
    id: '18',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-167 de 10 de abril de 2019 que ampara el derecho a la educación que se encuentra amenazado por el riesgo de colapso de la infraestructura de una sede educativa. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-167-19.htm',
  },
  {
    id: '19',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-145 de 2 de abril de 2019 que ampara la libertad de expresión de Red PaPaz y le ordena a Caracol Televisión S.A. y RCN Televisión S.A. la emisión de mensajes de bien público. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-145-19.htm',
  },
  {
    id: '20',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-120 de 18 de marzo de 2019 que ampara el derecho a la educación de un menor que padece TDAH. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/T-120-19.htm',
  },
  {
    id: '21',
    type: 'noticia',
    title:
      'Corte Constitucional Sentencia T-049 de 11 de febrero de 2019 que ampara el derecho a la libertad de cultos en el marco de un concurso de méritos para función docente. ',
    link: 'http://www.corteconstitucional.gov.co/relatoria/2019/t-049-19.htm',
  },
]

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const testWait = 0

export const getMenuItems = () => {
  return wait(testWait).then(() => menuItems)

  // const { origin } = window.location
  // return fetch(`${origin}/app-data/menu`)
  //   .then(response => response.json())
  //   .catch(console.error)
}

export const getCarouselItems = () => {
  return wait(testWait).then(() => carouselItems)

  // const { origin } = window.location
  // return fetch(`${origin}/app-data/carousel`)
  //   .then(response => response.json())
  //   .catch(console.error)
}

export const getPage = (section, pageSlug) => {
  return wait(testWait).then(() =>
    pages.find(p => (p.section === section && !pageSlug) || (p.section === section && p.slug === pageSlug))
  )

  // const { origin } = window.location
  // const slugPart = pageSlug ? `/${pageSlug}` : ''
  // const url = `${origin}/app-data/page/${section}${slugPart}`
  // return fetch(url)
  //   .then(response => response.json())
  //   .catch(console.error)
}

export const getTeamMembers = () => {
  return wait(testWait).then(() => teamMembers)

  // TODO: Implementation pending
  // const { origin } = window.location;
  // return fetch(`${origin}/app-data/team-members`)
  //   .then(response => response.json())
  //   .catch(console.error);
}

export const getClientQuotes = () => {
  return wait(testWait).then(() => clientQuotes)

  // TODO: Implementation pending
  // const { origin } = window.location;
  // return fetch(`${origin}/app-data/team-members`)
  //   .then(response => response.json())
  //   .catch(console.error);
}

export const getClientLogoImages = () => {
  return wait(testWait).then(() => clientLogoImages)

  // TODO: Implementation pending
  // const { origin } = window.location;
  // return fetch(`${origin}/app-data/team-members`)
  //   .then(response => response.json())
  //   .catch(console.error);
}

export const getNewsItems = () => {
  return wait(testWait).then(() => blogItems.filter(item => item.type === 'noticia'))

  // const { origin } = window.location
  // return fetch(`${origin}/app-data/news`)
  //   .then(response => response.json())
  //   .catch(console.error)
}

export const getBlogItems = () => {
  return wait(testWait).then(() => blogItems.filter(item => item.type === 'blog'))

  // const { origin } = window.location
  // return fetch(`${origin}/app-data/posts`)
  //   .then(response => response.json())
  //   .catch(console.error)
}
