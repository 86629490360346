import React, { useEffect } from 'react'
import { connect } from 'redux-zero/react'
import { Column } from './Column'
import { Footer } from './Footer'
import { Page } from './Page'
import { loadClientsPageData } from '../store'
import { ClientLogo } from './ClientLogo.jsx'
import { ClientsCarousel } from './ClientsCarousel.jsx'

export const Clients = connect(state => state)(({ clientQuotes, clientLogos }) => {
  useEffect(() => loadClientsPageData(), [])

  return (
    <Page>
      <Column>
        <div className="team-members">
          <ClientsCarousel items={clientQuotes} />
          <div className="client-logos">
            <h1>Nuestros Clientes</h1>
            {clientLogos.map(logo => (
              <ClientLogo key={logo.id} logo={logo} />
            ))}
          </div>
        </div>
      </Column>
      <Footer withLinkToContact />
    </Page>
  )
})
