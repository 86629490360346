import React, { useState, useEffect } from 'react'

const useBottomReached = () => {
  const [bottomReached, setBottomReached] = useState(true)

  const onScroll = () => {
    const reached = window.scrollY + window.innerHeight >= document.body.offsetHeight - 100
    setBottomReached(reached)
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    onScroll()
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return bottomReached
}

export const MoreContentArrow = () => {
  const bottomReached = useBottomReached()
  const className = `more-content-arrow ${bottomReached ? 'hidden' : ''}`
  return (
    <div className={className}>
      <i className="icon-right" />
    </div>
  )
}
