import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from './Header'

export const Layout = ({ children }) => (
  <div>
    <Helmet
      title="Jóvenes Siglo 21"
      meta={[
        { name: 'description', content: 'Jóvenes Siglo 21 website' },
        { name: 'keywords', content: 'educación, jóvenes' },
      ]}
    />
    <Header />
    <div className="mainContainer">{children}</div>
  </div>
)
