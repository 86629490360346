import React from "react";

export const Page = ({ children, bgImage }) => {
  const style = { backgroundImage: bgImage ? `url(${bgImage})` : "none" };
  return (
    <div className="article" style={style}>
      {children}
    </div>
  );
};
