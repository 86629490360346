import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Clients, ContentPage, Contact, IndexPage, Layout, NewsList, Team, TeamMember, Us } from './'
import { Provider } from 'redux-zero/react'
import store from '../store'

const App = ({ location }) => (
  <Layout>
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route exact path="/nosotros" component={Us} />
      <Route exact path="/equipo" component={Team} />
      <Route exact path="/equipo/:slug" component={TeamMember} />
      <Route exact path="/noticias" component={NewsList} />
      <Route exact path="/clientes" component={Clients} />
      <Route exact path="/noticias/:slug" component={NewsList} />
      <Route exact path="/contacto" component={Contact} />
      <Route exact path="/:section" component={ContentPage} />
      <Route exact path="/:section/:pageSlug" component={ContentPage} />
    </Switch>
  </Layout>
)

export class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    )
  }
}
