import React, { useEffect } from 'react'
import { connect } from 'redux-zero/react'
import { loadClientsPageData } from '../store'
import { Page, Column, ColumnContent, Footer, ClientLogo } from './'

export const Us = connect(state => state)(({ clientLogos }) => {
  useEffect(() => loadClientsPageData(), [])

  return (
    <Page>
      <Column color="alpha-blue">
        <ColumnContent layout={1}>
          <h1>Educando Jóvenes 21</h1>
          <p>
            Somos una entidad sin ánimo de lucro que desarrolla proyectos de apoyo a la educación con el propósito de
            promover tres objetivos fundamentales:
          </p>
          <ol>
            <li>
              <strong>Protección Integral:</strong> Contribuimos recursos y prestamos asesoría especializada a
              instituciones que ofrecen servicios de atención integral a la primera infancia. Adicionalmente, abogamos
              por la garantía de los derechos prevalentes e interdependientes de la niñez y la adolescencia.
            </li>
            <li>
              <strong>Equidad:</strong> Desarrollamos proyectos para eliminar las barreras de acceso a la educación
              preescolar, básica y media y a la educación superior. Creemos que el acceso a una educación de calidad
              resulta esencial para el logro de la equidad y la movilidad social.
            </li>
            <li>
              <strong>Corresponsabilidad:</strong> Trabajamos para visibilizar los derechos de la niñez y la
              adolescencia y abogamos porque el Estado, la familia y la sociedad contribuyan a la garantía de estos
              derechos. Apoyamos los esfuerzos de otras entidades para lograr la transformación de la vida de las
              personas.
            </li>
          </ol>
          <p>
            Desarrollamos un modelo de gestión innovador basado en la austeridad y en el uso más eficiente de los
            recursos. Somos gestores de un esquema de filantropía de alto impacto que desarrolla proyectos basados en la
            mejor evidencia disponible.
          </p>
          <h2>Nuestros Aliados:</h2>

          <div className="client-logos">
            {clientLogos.map(logo => (
              <ClientLogo key={logo.id} logo={logo} />
            ))}
          </div>
        </ColumnContent>
      </Column>
      {/* <Column size="big">
        <ColumnContent layout={1}>
          <div className="layout-one-image" style={{ backgroundImage: `url(/images/bg-nosotros.jpg)` }} />
        </ColumnContent>
      </Column> */}
      <Footer withLinkToContact />
    </Page>
  )
})
