import React, { Component } from 'react'
import { connect } from 'redux-zero/react'
import * as actions from '../store'
import { Page } from './Page'
import { Column } from './Column'
import { ServiceTitleBox } from './ServiceTitleBox'
import { Footer } from './Footer'

const scrollToNewsItem = itemSlug => {
  const newsItem = document.getElementById(itemSlug)
  if (newsItem) {
    var topPos = newsItem.offsetTop
    document.getElementById('news-list').scrollTop = topPos - 80
  }
}

export class NewsList extends Component {
  componentDidMount() {
    actions.loadNewsItems()
    actions.loadBlogItems()

    const { slug } = this.props.match && this.props.match.params
    if (slug) {
      setTimeout(() => scrollToNewsItem(slug), 1000)
    }
  }

  render() {
    const { blogItems, newsItems } = this.props
    return (
      <Page>
        <Column>
          <div className="news-list">
            <h1>Nuestras noticias</h1>
            {newsItems.map(({ slug, title, link }) => (
              <ServiceTitleBox key={slug} content={title} iconClassName="icon-plus" link={link} />
            ))}
          </div>
        </Column>
        <Footer />
      </Page>
    )
  }
}

NewsList = connect(state => state)(NewsList)
