import createStore from 'redux-zero'
import * as api from './api'

const store = createStore({
  menuItems: [],
  carouselItems: [],
  loadingPage: false,
  contactFormSent: false,
  contactForm: {
    name: '',
    email: '',
    message: '',
    robot: '',
  },
  teamMembers: [],
  clientQuotes: [],
  clientLogos: [],
  newsItems: [],
})

export const loadCarouselItems = () => {
  api.getCarouselItems().then(carouselItems => store.setState({ carouselItems }))
}

export const loadMenuItems = () => {
  api.getMenuItems().then(menuItems => store.setState({ menuItems }))
}

export const loadPage = (section, slug) => {
  const state = store.getState()
  const pageKey = slug ? `page-${section}-${slug}` : `page-${section}`
  if (!state[pageKey] || !state[pageKey].id) {
    store.setState({ loadingPage: true })
    api
      .getPage(section, slug)
      .then(page => store.setState({ [pageKey]: page, loadingPage: false }))
      .catch(error => {
        console.log(error)
        store.setState({ loadingPage: false })
      })
  }
}

export const loadNewsItems = () => {
  api.getNewsItems().then(newsItems => store.setState({ newsItems }))
}

export const loadTeamMembers = () => {
  store.setState({ loadingPage: true })
  api.getTeamMembers().then(teamMembers => {
    store.setState({ teamMembers })
    store.setState({ loadingPage: false })
  })
}

export const loadClientsPageData = () => {
  store.setState({ loadingPage: true })
  Promise.all([api.getClientQuotes(), api.getClientLogoImages()]).then(([quotes, logos]) => {
    store.setState({ clientQuotes: quotes })
    store.setState({ clientLogos: logos })
    store.setState({ loadingPage: false })
  })
}

export const loadBlogItems = () => {
  api.getBlogItems().then(blogItems => store.setState({ blogItems }))
}

export const onContactFormFieldChange = event => {
  const { contactForm } = store.getState()
  contactForm[event.target.name] = event.target.value
  store.setState({ contactForm })
}

export const onContactFormRobotFieldChange = event => {
  const { contactForm } = store.getState()
  contactForm.robot = contactForm.robot === 'checked' ? '' : 'checked'
  // TODO: FIX: Adding a random value to force update.
  store.setState({ contactForm, a: Math.random() })
}

export const sendContactForm = event => {
  event.preventDefault()
  const { contactForm } = store.getState()
  console.log('====================================')
  console.log('sendEmail')
  console.log('====================================')
  store.setState({ contactFormSent: true })
}

export default store
