import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as actions from '../store'
import { HeaderLink } from './HeaderLink'
import { connect } from 'redux-zero/react'

const HeaderComponent = props => {
  const [state, setState] = useState({ menuVisible: false })
  const { pathname } = useLocation()

  useEffect(() => {
    actions.loadMenuItems()
  }, [])

  const onMenuButtonClick = () => {
    setState({ menuVisible: !state.menuVisible })
  }

  const onMenuItemClick = () => {
    setState({ menuVisible: false })
  }

  const { menuVisible } = state
  const { menuItems = [] } = props

  return (
    <div className={`header ${pathname === '/' ? 'main-page' : ''}`}>
      <Link to="/">
        <div className="logo" />
      </Link>
      <ul className={`${menuVisible ? 'visible' : ''}`}>
        <button className="menu-button" onClick={onMenuButtonClick}>
          <span />
          <span />
          <span />
        </button>
        {menuItems.map(item => (
          <HeaderLink key={item.id} {...item} onClick={onMenuItemClick}>
            {item.text}
          </HeaderLink>
        ))}
      </ul>
    </div>
  )
}

export const Header = connect(state => state)(HeaderComponent)
